<template>
  <div
    class="d-flex flex-column"
    style="height: calc(100vh - 40px - 66px)"
    >
    <v-dialog
      v-model="dialog"
      width="600"
      >
      <v-card>
        <v-card-title>
          Acciones
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item-group
              color="primary"
              >
              <v-list-item
                color="error"
                @click="deleting = true"
                >
                <v-list-item-avatar>
                  <v-icon
                    color="error"
                    >
                    mdi-delete
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    class="error--text"
                    >
                    Eliminar mensaje
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleting"
      width="600"
      >
      <v-card>
        <v-card-title>
          Eliminar mensaje
        </v-card-title>
        <v-card-text>
          ¿Está seguro de que desea eliminar el mensaje? Esta acción <span class="font-weight-medium">no se puede deshacer</span>.
        </v-card-text>
        <v-card-actions
          class="justify-end"
          >
          <v-btn
            text
            @click="deleting = false"
            class="mr-1"
            >
            Cancelar
          </v-btn>
          <v-btn
            outlined
            color="error"
            @click="deleteMessage"
            >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div
      id="messages"
      class="fill-height d-flex flex-column-reverse"
      ref="messages"
      style="overflow: scroll; padding-bottom: 80px; padding-top: 40px"
      >
      <div
        v-intersect="intersection"
        class="text-center"
        >
      </div>
      <template
        v-for="(message, idx) in messages"
        >
        <div
          :class="(messages[idx + 1] || {}).profileId == message.profileId ? '' : 'pb-2'"
          @click="openDialog(message)"
          >
          <div
            class="mt-2 d-flex align-end"
            style="max-width: 80%"
            :class="message.profileId == currentUser.selectedProfile.id ? 'ml-auto justify-end' : 'mr-auto justify-start'"
            >
            <v-avatar
              v-if="message.profileId != currentUser.selectedProfile.id "
              class="mr-2"
              size="25"
              >
              <template
                v-if="(messages[idx + 1] || {}).profileId != message.profileId"
                >
                <v-img
                  :src="url + message.profilePicture"
                  v-if="message.profilePicture"
                  ></v-img>
                <v-icon
                  v-else
                  color="grey"
                  >
                  mdi-account-circle
                </v-icon>
              </template>
            </v-avatar>

            <div
              class="fill-width"
              v-if="message.sharableId"
              >
              <v-subheader
                :class="message.profileId == currentUser.selectedProfile.id ? 'justify-end' : 'justify-start'"
                class="px-0"
                >
                {{ message.profileId == currentUser.selectedProfile.id ? 'Compartiste' : 'Compartió' }} una publicación
              </v-subheader>
              <post
                idx="0"
                :postId="message.sharableId"
                share
                ></post>
            </div>

            <v-card
              v-else
              class="grow"
              rounded="lg"
              :ripple="false"
              style="width: fit-content"
              >
              <v-card-text
                class="py-2"
                >
                {{ message.body }}
              </v-card-text>
            </v-card>

            <v-avatar
              class="ml-2"
              v-if="message.profileId == currentUser.selectedProfile.id"
              size="25"
              >
              <template
                v-if="(messages[idx + 1] || {}).profileId != message.profileId"
                >
                <v-img
                  :src="url + message.profilePicture"
                  v-if="message.profilePicture"
                  ></v-img>
                <v-icon
                  v-else
                  color="grey"
                  >
                  mdi-account-circle
                </v-icon>
              </template>
            </v-avatar>
          </div>
        </div>
      </template>
    </div>

    <div
      class="fill-width mt-4 pr-4"
      style="position: fixed; margin: 0 auto"
      :style="$vuetify.breakpoint.mobile ? 'width: 100%; bottom: 60px' : 'width: 880px; bottom: 0'"
      >
      <v-textarea
        class="rounded-0"
        style="background-color: white"
        outlined
        v-model="message"
        label="Nuevo mensaje"
        append-icon="mdi-send"
        @click:append="sendMessage"
        @keyup.enter="sendMessage"
        hide-details
        :loading="loading"
        :disabled="loading"
        rows="2"
        ></v-textarea>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { Messages } from '@/graphql/queries/chats'
import { CreateMessage, DeleteMessage } from '@/graphql/mutations/social/chats'
import { NewMessage, DeletedMessage } from '@/graphql/subscriptions/chats'

const Post = () => import('@/components/posts/Card')

export default {
  props: {
    chatId: {
      required: true,
      type: String | Number
    }
  },

  data () {
    return {
      messages: [],
      firstLoad: true,
      loading: false,
      message: null,
      page: 0,
      end: false,
      selected: null,
      dialog: false,
      deleting: false,
    }
  },

  apollo: {
    $subscribe: {
      newMessage: {
        query: NewMessage,
        variables () {
          return {
            chatId: this.chatId
          }
        },
        result ({ data }) {
          this.messages.unshift(data.newMessage.message)
          this.$nextTick( () => {
            document.getElementById('messages').scrollTo(0, document.getElementById('messages').scrollHeight)
          })
        },
      },

      deletedMessage: {
        query: DeletedMessage,
        variables () {
          return {
            chatId: this.chatId
          }
        },
        result ({ data }) {
          var message = this.messages.find ( message => message.id == data.deletedMessage.message.id)
          var idx = this.messages.indexOf(message)
          debugger
          this.messages.splice(idx, 1)
        },
      },
    },
  },

  watch: {
    page () {
      this.fetchMessages ()
    }
  },

  computed: {
    ...mapGetters(['currentUser', 'url']),
  },

  methods: {
    fetchMessages () {
      this.$apollo.query({
        query: Messages,
        variables: {
          chatId: this.chatId,
          page: this.page
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        if (!res.data.messages.length) {
          this.end = true
        }

        this.messages.unshift(...res.data.messages)

        if (this.page == 1) {
          setTimeout( () => {
            document.getElementById('messages').scrollTo(0, document.getElementById('messages').scrollHeight)
          }, 500)
        } else {
          if (this.$refs.messages.scrollTop == 0) {
            var initialHeight = this.$refs.messages.scrollHeight
            this.$nextTick(() => {
              this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight - initialHeight
            })
          }
        }
      })
    },

    sendMessage () {
      if (this.message) {
        this.loading = true
        this.$apollo.mutate({
          mutation: CreateMessage,
          variables: {
            input: {
              attributes: {
                chatId: this.$route.params.id,
                profileId: this.currentUser.selectedProfile.id,
                body: this.message,
              }
            }
          }
        }).then ( res => {
          this.message = null
          this.loading = false
        })
      }
    },

    intersection (val) {
      if (this.firstLoad && this.page == 1) return this.firstLoad = false
      if (!this.loading && !this.end) {
        this.page ++
      }
    },

    openDialog (message) {
      if (message.profileId == this.currentUser.selectedProfile.id) {
        this.selected = message
        this.dialog = true
      }
    },

    deleteMessage () {
      this.$apollo.mutate({
        mutation: DeleteMessage,
        variables: {
          input: {
            id: this.selected.id
          }
        }
      }).then ( res => {
        this.deleting = false
        this.dialog = false
        this.selected = null
      })
    }
  },

  components: { Post }
}
</script>
