import gql from 'graphql-tag'

export const CreateMessage = gql`
  mutation createMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      success
    }
  }
`

export const DeleteMessage = gql`
  mutation deleteMessage($input: DeleteMessageInput!) {
    deleteMessage(input: $input) {
      success
    }
  }
`
