<template>
  <section
    style="max-width: 880px !important; margin: 0 auto"
    class="fill-height d-flex flex-column"
    v-if="chat && currentUser"
    >
    <v-card
      tile
      flat
      color="transparent"
      class="fill-height"
      >
      <v-card-title
        style="position: fixed; z-index: 10"
        :style="$vuetify.breakpoint.mobile ? 'width: 100%' : 'width: 880px'"
        class="white py-2 elevation-2 align-center"
        >
        <v-icon
          @click="$router.go(-1)"
          class="mr-2"
          >mdi-chevron-left</v-icon>
        <span
          class="body-1"
          >
          Conversación con {{ counterpart.name }}
        </span>
      </v-card-title>
      <v-card-text
        class="px-2 pb-0"
        >
        <messages
          :chatId="chat.id"
          ></messages>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { Chat } from '@/graphql/queries/chats'

import Messages from '@/components/social/chats/Messages'

export default {
  data () {
    return {
      chat: null,
    }
  },

  created () {
    this.fetchChat ()
  },

  methods: {
    fetchChat () {
      this.$apollo.query({
        query: Chat,
        variables: {
          id: this.$route.params.id
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.chat = res.data.chat
      })
    },
  },
  
  computed: {
    ...mapGetters(['currentUser']),

    counterpart () {
      return this.chat.profiles.find( v => v.id != this.currentUser.selectedProfile.id)
    }
  },

  components: { Messages }
}
</script>
