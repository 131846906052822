<template>
  <v-list-item
    @click="redirect"
    >
    <v-list-item-avatar>
      <v-img
        width="50"
        height="50"
        :src="url + counterpart.picture.thumbUrl"
        v-if="counterpart.picture"
        ></v-img>
      <v-icon
        v-else
        size="50"
        >
        mdi-account-circle
      </v-icon>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title v-html="counterpart.name"></v-list-item-title>
      <v-list-item-subtitle v-html="chat.lastMessage"></v-list-item-subtitle>
      <v-list-item-subtitle 
        class="caption d-flex justify-space-between secondary--text"
        >
        <span>
          Chat con un <span class="font-weight-medium">{{ counterpart.profilableType == 'Store' ? 'Partner' : 'Fan' }}</span>
        </span>

        <span
          v-if="!read"
          >
          <v-icon
            x-small
            style="margin-top: -2px"
            color="secondary"
            >
            mdi-bell
          </v-icon>
          Mensajes sin leer
        </span>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    chat: {
      required: true,
      type: Object
    }
  },

  computed: {
    ...mapGetters(['currentUser', 'url']),

    counterpart () {
      return this.chat.profiles.find( profile => profile.id != this.currentUser.selectedProfile.id )
    },

    read () {
      return this.chat.participants.find( profile => profile.profileId == this.currentUser.selectedProfile.id ).read
    }
  },

  methods: {
    redirect () {
      this.$router.push({ name: 'chat', params: { id: this.chat.id } })
    }
  }
}
</script>
