<template>
  <v-container
    class="pa-0"
    style="max-width: 982px"
    v-if="currentUser"
    >
    <v-list 
      v-if="chats.length"
      class="pb-0"
      two-line
      >
      <template v-for="(chat, index) in chats">
        <chat-item
          :key="chat.id"
          :chat="chat"
          ></chat-item>
        <v-divider
          ></v-divider>
      </template>
    </v-list>

    <v-card
      v-else
      outlined
      class="mt-3 mx-3"
      height="100"
      >
      <v-card-text
        class="d-flex align-center justify-center fill-height"
        >
        ¡Parece que aún no tienes conversaciones!
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex' 
import { Chats } from '@/graphql/queries/chats'
import { NewChat } from '@/graphql/subscriptions/chats'

import ChatItem from '@/components/social/chats/Item'

export default {
  components: { ChatItem },

  data () {
    return {
      chats: []
    }
  },

  watch: {
    currentUser: {
      handler (val) {
        const observer = this.$apollo.subscribe({
          query: NewChat,
          variables: {
            profileId: val.selectedProfile.id
          },
        })

        var self = this

        observer.subscribe({
          next (data) {
            self.fetchChats()
          },
          error (error) {
          },
        })
      }
    }
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  created () {
    this.fetchChats()
  },

  methods: {
    fetchChats () {
      this.$apollo.query({
        query: Chats,
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.chats = res.data.chats
      })
    }
  }
}
</script>
